import { Web3Provider } from "@ethersproject/providers";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import { injectedConnector } from "./Contract/Connectors";
import { USE_LOCAL_DATA_ONLY } from "./Contract/contract-constants";
import "./Header.scss";

type HeaderItem = {
  text: string;
  onClick: () => void;
};

interface HeaderProps {
  ownsMyto: boolean;
  scrollToCollection: () => void;
  scrollToClaimable: () => void;
  scrollToMytoDex: () => void;
  scrollToProjectStats: () => void;
  scrollToFAQ: () => void;
  scrollToRoadMap: () => void;
}

const Header = (props: HeaderProps) => {
  const { activate, account } = useWeb3React<Web3Provider>();
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);

  const headerItems = React.useMemo<Array<HeaderItem>>(() => {
    let headerItems = props.ownsMyto
      ? [{ text: "Your Collection", onClick: props.scrollToCollection }]
      : [];
    headerItems = headerItems.concat([
      {
        text: "Claim",
        onClick: props.scrollToClaimable,
      },
    ]);
    if (!USE_LOCAL_DATA_ONLY) {
      headerItems = headerItems.concat([
        {
          text: "All MytoBytes",
          onClick: props.scrollToMytoDex,
        },
        {
          text: "Stats",
          onClick: props.scrollToProjectStats,
        },
      ]);
    }
    headerItems = headerItems.concat([
      {
        text: "FAQ",
        onClick: props.scrollToFAQ,
      },
      {
        text: "Roadmap",
        onClick: props.scrollToRoadMap,
      },
    ]);
    return headerItems;
  }, [
    props.ownsMyto,
    props.scrollToCollection,
    props.scrollToClaimable,
    props.scrollToMytoDex,
    props.scrollToProjectStats,
    props.scrollToFAQ,
    props.scrollToRoadMap,
  ]);

  return (
    <header className="Page-Header">
      <div className="Page-Header__mobile-hamburger">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setMobileMenuOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          {...{
            anchor: "left",
            open: mobileMenuOpen,
            onClose: () => setMobileMenuOpen(false),
            transitionDuration: 150,
          }}
        >
          <Box
            sx={{
              width: 250,
              bgcolor: "#212428",
              color: "white",
              height: "100%",
            }}
            role="presentation"
          >
            <List>
              {headerItems.map((headerItem: HeaderItem) => {
                return (
                  <ListItem
                    button
                    key={headerItem.text}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      // Use timeout here because closing menu and scrolling at same time seems to cancel scroll
                      setTimeout(() => {
                        headerItem.onClick();
                      }, 200);
                    }}
                  >
                    <ListItemText primary={headerItem.text} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      </div>
      <div className="Page-Header-left-buttons">
        {headerItems.map((headerItem: HeaderItem) => {
          return (
            <Link
              onClick={headerItem.onClick}
              component="button"
              sx={{ fontSize: "16px" }}
              color="inherit"
              underline="none"
            >
              {headerItem.text}
            </Link>
          );
        })}
      </div>
      <div className="Page-Header__connect-button">
        {!account ? (
          <Button onClick={() => activate(injectedConnector)}>
            Connect With MetaMask
          </Button>
        ) : (
          <Button disabled>Connected!</Button>
        )}
      </div>
    </header>
  );
};

export default Header;
