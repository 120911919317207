import "./Tooltip.scss";
import MuiTooltip from "@mui/material/Tooltip";

import React from "react";

import { IoHelpCircleOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";

type TooltipProps = {
  message: string;
};

const Tooltip: React.FC<TooltipProps> = (props) => {
  return (
    <span className="Tooltip__root">
      <MuiTooltip title={props.message}>
        <IconButton>
          <IoHelpCircleOutline className="Tooltip__icon" />
        </IconButton>
      </MuiTooltip>
    </span>
  );
};

export default Tooltip;
