import { Web3Provider } from "@ethersproject/providers";
import { Button, Link } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import Modal from "react-modal";
import { DISCORD_URL, TWITTER_URL, WEBSITE } from "./constants";
import { USE_LOCAL_DATA_ONLY } from "./Contract/contract-constants";
import getMerkleTreeProof from "./Contract/MerkleTree";
import "./Footer.scss";
import { etherScanTokenLinkForChainID } from "./utils";

const Footer = () => {
  const { chainId } = useWeb3React<Web3Provider>();

  const [tosOpen, setToSOpen] = React.useState(false);
  const [merkleCalculatorOpen, setMerkleCalculatorOpen] = React.useState(false);
  const [address, setAddress] = React.useState<string | null>(null);
  const [merkleProof, setMerkleProof] = React.useState<string | null>(null);

  const onSubmitInput = React.useCallback(() => {
    if (!address) {
      setMerkleProof(null);
      return;
    }
    let output = "[";
    const proof = getMerkleTreeProof(address);
    output += proof.join(",");
    output += "]";
    setMerkleProof(output);
  }, [address]);

  return (
    <>
      <div className="Footer">
        <div>
          <strong>MytoBytes</strong> {/* TODO: put a logo here */}
          <div>
            A{" "}
            <Link href={WEBSITE} target="_blank" rel="noopener noreferrer">
              DieNamX
            </Link>{" "}
            project
          </div>
        </div>
        <div className="Footer__right-buttons">
          <Link
            href={TWITTER_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => gtag && gtag("event", "click_twitter")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ffffff"
                d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
              />
            </svg>
          </Link>
          <Link
            href={DISCORD_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => gtag && gtag("event", "click_discord")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 127.14 96.36"
            >
              <g id="图层_2" data-name="图层 2">
                <g id="Discord_Logos" data-name="Discord Logos">
                  <g
                    id="Discord_Logo_-_Large_-_White"
                    data-name="Discord Logo - Large - White"
                  >
                    <path
                      fill="#ffffff"
                      d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </Link>
          {!USE_LOCAL_DATA_ONLY && (
            <Link onClick={() => setToSOpen(true)}>Terms of Service</Link>
          )}
          {!USE_LOCAL_DATA_ONLY && chainId && (
            <Link
              href={etherScanTokenLinkForChainID(chainId, null)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Smart Contract
            </Link>
          )}
          {!USE_LOCAL_DATA_ONLY && (
            <Link onClick={() => setMerkleCalculatorOpen(true)}>
              Merkle Calculator
            </Link>
          )}
        </div>
      </div>
      <Modal isOpen={merkleCalculatorOpen}>
        <p style={{ color: "black" }}>
          Tool is to calculate a Merkle Proof for an Ethereum address. Needed to
          mint on the whitelist if using something like EtherScan.
        </p>
        <p style={{ color: "black" }}>
          Address: <input onChange={(e) => setAddress(e.target.value)} />
        </p>
        <Button onClick={onSubmitInput}>Submit</Button>
        {merkleProof && (
          <p style={{ color: "black" }}>
            Merkle Proof for Address: {merkleProof}
          </p>
        )}
        <div>
          <Button onClick={() => setMerkleCalculatorOpen(false)}>Close</Button>
        </div>
      </Modal>
      <Modal isOpen={tosOpen}>
        <div style={{ color: "black" }}>
          <h2>TERMS & CONDITIONS</h2>
          MytoBytes are a collection of digital artworks (NFTs) running on the
          Ethereum network. This website is only an interface allowing
          participants to exchange digital collectibles. Users are entirely
          responsible for the safety and management of their own private
          Ethereum wallets and validating all transactions and contracts
          generated by this website before approval. Furthermore, as the
          MytoBytes smart contract runs on the Ethereum network, there is no
          ability to undo, reverse, or restore any transactions. This website
          and its connected services are provided “as is” and “as available”
          without warranty of any kind. By using this website you are accepting
          sole responsibility for any and all transactions involving MytoBytes
          digital collectibles.
          <h3>OWNERSHIP</h3>
          i. You Own the NFT. Each MytoByte is an NFT on the Ethereum
          blockchain. When you purchase an NFT, you own the underlying MytoByte,
          the Art, completely. Ownership of the NFT is mediated entirely by the
          Smart Contract and the Ethereum Network: at no point may we seize,
          freeze, or otherwise modify the ownership of any MytoByte. ii.
          Personal Use. Subject to your continued compliance with these Terms,
          DieNamX grants you a worldwide, royalty-free license to use, copy, and
          display the purchased Art, along with any extensions that you choose
          to create or use, solely for the following purposes: (i) for your own
          personal, non-commercial use; (ii) as part of a marketplace that
          permits the purchase and sale of your MytoByte / NFT, provided that
          the marketplace cryptographically verifies each MytoByte owner’s
          rights to display the Art for their MytoByte to ensure that only the
          actual owner can display the Art; or (iii) as part of a third party
          website or application that permits the inclusion, involvement, or
          participation of your MytoByte, provided that the website/application
          cryptographically verifies each MytoByte owner’s rights to display the
          Art for their MytoByte to ensure that only the actual owner can
          display the Art, and provided that the Art is no longer visible once
          the owner of the MytoByte leaves the website/application. iii.
          Commercial Use. Subject to your continued compliance with these Terms,
          DieNamX grants you an unlimited, worldwide license to use, copy, and
          display the purchased Art for the purpose of creating derivative works
          based upon the Art (“Commercial Use”). Examples of such Commercial Use
          would e.g. be the use of the Art to produce and sell merchandise
          products (T-Shirts etc.) displaying copies of the Art. For the sake of
          clarity, nothing in this Section will be deemed to restrict you from
          (i) owning or operating a marketplace that permits the use and sale of
          MytoBytes generally, provided that the marketplace cryptographically
          verifies each MytoByte owner’s rights to display the Art for their
          MytoByte to ensure that only the actual owner can display the Art;
          (ii) owning or operating a third party website or application that
          permits the inclusion, involvement, or participation of MytoBytes
          generally, provided that the third party website or application
          cryptographically verifies each MytoByte owner’s rights to display the
          Art for their MytoByte to ensure that only the actual owner can
          display the Art, and provided that the Art is no longer visible once
          the owner of the Purchased MytoByte leaves the website/application; or
          (iii) earning revenue from any of the foregoing. iv. DieNamX LLC.
          reserves the right to continue using MytoBytes artwork for marketing
          and commercial purposes.
          <h3>Changes to the Terms and Conditions</h3>
          We may make changes to the Terms at our discretion. Please check these
          Terms periodically for changes. Any changes to the Terms will apply on
          the date that they are made, and your continued access to or use after
          the Terms have been updated will constitute your binding acceptance of
          the updates. If you do not agree to any revised Terms, you may not
          access or use the Site.
          <h3>Fees and Payment</h3>
          A. If you elect to purchase a MytoByte through the Site, any financial
          transactions that you engage in will be conducted solely through the
          Ethereum network. We will have no insight into or control over these
          payments or transactions, nor do we have the ability to reverse any
          transactions. We will have no liability to you or to any third party
          for any claims or damages that may arise as a result of any
          transactions that you engage or any other transactions that you
          conduct via the Ethereum network. B. Ethereum requires the payment of
          a transaction fee (a “Gas Fee”) for every transaction that occurs on
          the Ethereum network. The Gas Fee funds the network of computers that
          run the decentralized Ethereum network. This means that you will need
          to pay a Gas Fee for each transaction.
          <h3>Indemnification</h3>
          You agree to hold harmless and indemnify DieNamX LLC. and its
          subsidiaries, affiliates, officers, agents, employees, advertisers,
          licensors, suppliers or partners from and against any claim,
          liability, loss, damage (actual and consequential) of any kind or
          nature, suit, judgment, litigation cost, and reasonable attorneys'
          fees arising out of or in any way related to (i) your breach of these
          Terms, (ii) your misuse of the Site, or (iii) your violation of
          applicable laws, rules or regulations in connection with your access
          to or use of the Site.
          <h3>Dispute Resolution; Arbitration</h3>
          All disputes arising out of or in connection with these Terms,
          including without limitation your access or use of the Site, or to any
          products sold or distributed through the Site, will be referred to and
          finally resolved by arbitration. The case will be adjudicated by a
          single arbitrator. Each party will cover its own fees and costs
          associated with the arbitration proceedings. The award of the
          arbitrator will be final and binding, and any judgment on the award
          rendered by the arbitrator may be entered in any court of competent
          jurisdiction. Notwithstanding the foregoing, we may seek and obtain
          injunctive relief in any jurisdiction in any court of competent
          jurisdiction. WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO
          THESE TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE SITE
          OR ANY PRODUCTS SOLD OR DISTRIBUTED THROUGH THE SITE, OR THE SMART
          CONTRACTS: (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL
          BY JURY; AND (II) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO
          PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT,
          INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH
          DISPUTE.
          <h3>Disclaimers</h3>
          A. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF
          THE SITE IS AT YOUR SOLE RISK, AND THAT THE SITE IS PROVIDED "AS IS"
          AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
          WE MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED
          WARRANTIES REGARDING THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT
          LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY EXTERNAL WEBSITES),
          INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR
          RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR
          SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO
          YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SITE WILL MEET YOUR
          REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE SITE WILL BE
          UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE DATA
          PROVIDED THROUGH THE SITE WILL BE ACCURATE, (III) THE SITE OR ANY
          CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SITE
          ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA
          THAT YOU DISCLOSE WHEN YOU USE THE SITE WILL BE SECURE. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN
          CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY
          NOT APPLY TO YOU. B. YOU ACCEPT THE INHERENT SECURITY RISKS OF
          PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE
          THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY
          UNLESS IT IS DUE TO OUR WILLFUL MISCONDUCT. C. WE WILL NOT BE
          RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF
          YOUR USE OF THE ETHEREUM NETWORK NOR DO WE HAVE NO CONTROL OVER AND
          MAKE NO GUARANTEES REGARDING ANY SMART CONTRACTS.
          <h3>Limitation of Liability</h3>
          A. YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR TO
          ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
          OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER
          ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF
          PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR
          BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE
          GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. B. YOU AGREE THAT OUR
          TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT
          OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR
          INABILITY TO ACCESS OR USE) ANY PORTION OF THE SITE, WHETHER IN
          CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS
          LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER
          THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE,
          OR (B) $500. C. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE
          AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE
          WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN. WE
          WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT THESE
          LIMITATIONS.
          <h3>Risk Assumption</h3>
          You accept and acknowledge each of the following: A. To the extent
          that you sell your MytoByte NFT, please be aware that the prices of
          NFTs are extremely volatile and fluctuations in the prices of other
          NFTs and impact the price of your MytoByte both positively and
          negatively. Given the volatility, NFTs such as MytoByte should not be
          considered an investment. You assume all risks in that connection. B.
          Ownership of a MytoByte confers ownership of digital artwork only.
          Accordingly, no information on this Site (or any other documents
          mentioned therein) is or may be considered to be advice or an
          invitation to enter into an agreement for any investment purpose.
          Further, nothing on this Site qualifies or is intended to be an
          offering of securities in any jurisdiction nor does it constitute an
          offer or an invitation to purchase shares, securities or other
          financial products. Due to the artistic nature of the project,
          MytoBytes has not been registered with or approved by any regulator in
          any jurisdiction. It remains your sole responsibility to assure that
          the purchase of the MytoByte and the associated art is in compliance
          with laws and regulations in your jurisdiction. C. You assume all
          risks associated with using an Internet-based currency, including, but
          not limited to, the risk of hardware, software and Internet
          connections, the risk of malicious software introduction, and the risk
          that third parties may obtain unauthorized access to information
          stored within your wallet. D. NFTs, cryptocurrencies and blockchain
          technology are relatively new and the regulatory landscape is
          unsettled. New regulations could negatively impact such technologies
          impacting the value for your MytoByte. You understand and accept all
          risk in that regard. E. You assume all responsibility for any adverse
          effects of disruptions or other issues impacting Ethereum or the
          Ethereum platform.
        </div>
        <br />
        <Button onClick={() => setToSOpen(false)}>Close</Button>
      </Modal>
    </>
  );
};

export default Footer;
