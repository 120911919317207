import { Button } from "@mui/material";
import React from "react";
import "./Community.scss";
import { DISCORD_URL } from "./constants";

const Community = () => {
  return (
    <div className="Community">
      <div className="Community__detail">
        <h1>Join our community on Discord</h1>
        <p>
          Discussion, giveaways, contests, updates & more can be found here!
        </p>
        <Button
          variant={"contained"}
          size="large"
          onClick={() => {
            window.open(DISCORD_URL, "_blank");
            gtag && gtag("event", "click_discord");
          }}
        >
          Join us on Discord
        </Button>
      </div>
      <iframe
        className="Community__widget"
        src="https://discord.com/widget?id=884312312574513162&theme=dark"
        width="350"
        height="350"
        allow-transparency="true"
        frame-border="0"
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      />
    </div>
  );
};

export default Community;
