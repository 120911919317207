import { BigNumber } from "@ethersproject/bignumber";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import ClaimableMytos from "./ClaimableMytos";
import Collection from "./Collection";
import Community from "./Community";
import PageSection from "./Components/PageSection";
import { injectedConnector, networkConnector } from "./Contract/Connectors";
import {
  INITIAL_ENERGY_AMOUNT,
  MAX_CLAIMABLE_TOKEN_ID,
  MAX_NUMBER_OF_MYTOS,
  USE_LOCAL_DATA_ONLY,
} from "./Contract/contract-constants";
import {
  energyRequiredToMint,
  getEnergyBlockLevel,
  getEnergyFoodLevel,
  getOwnedTokenDataForAccount,
} from "./Contract/ContractInterface";
import FAQ from "./FAQ";
import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";
import "./Home.scss";
import MytoByteDetailModal from "./MytoByteDetailModal";
import MytoDex from "./MytoDex";
import ProjectStats from "./ProjectStats";
import Roadmap from "./Roadmap";

function Home() {
  const collectionRef = React.useRef<HTMLDivElement>(null);
  const claimableRef = React.useRef<HTMLDivElement>(null);
  const mytodexRef = React.useRef<HTMLDivElement>(null);
  const projectStatsRef = React.useRef<HTMLDivElement>(null);
  const faqRef = React.useRef<HTMLDivElement>(null);
  const roadmapRef = React.useRef<HTMLDivElement>(null);
  let match = useRouteMatch<{ myto: string }>("/myto/:myto");

  let history = useHistory();
  const [ownedTokenIDs, setOwnedTokenIDs] =
    React.useState<Array<number> | null>(null);
  const [energyRequired, setEnergyRequired] = React.useState<
    BigNumber | undefined
  >(undefined);
  const [energyBlockLevel, setEnergyBlockLevel] = React.useState<
    Array<BigNumber>
  >([]);
  const [energyFoodLevel, setEnergyFoodLevel] = React.useState<
    Array<BigNumber>
  >([]);
  const [currentBlockNumber, setCurrentBlockNumber] = React.useState<
    number | undefined
  >(undefined);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedTokenID, setSelectedTokenID] = React.useState<number>(0);
  const [currentBlockTimestamp, setCurrentBlockTimestamp] =
    React.useState<number>(0);

  const { account, library, active, activate, chainId } =
    useWeb3React<Web3Provider>();
  const updateCurrentState = async () => {
    if (USE_LOCAL_DATA_ONLY) {
      setEnergyRequired(BigNumber.from(INITIAL_ENERGY_AMOUNT));
      return;
    }
    if (!library || !chainId) {
      return;
    }
    const [blockNumber, energyBlockLevels, energyFoodLevels, energyReqd] =
      await Promise.all([
        library.getBlockNumber(),
        getEnergyBlockLevel(library, chainId),
        getEnergyFoodLevel(library, chainId),
        energyRequiredToMint(library, chainId),
      ]);
    setCurrentBlockNumber(blockNumber);
    setEnergyBlockLevel(energyBlockLevels);
    setEnergyFoodLevel(energyFoodLevels);
    setEnergyRequired(energyReqd);
    if (!!account) {
      setOwnedTokenIDs(
        await getOwnedTokenDataForAccount(account, library, chainId)
      );
    } else {
      setOwnedTokenIDs([]);
    }
    const block = await library.getBlock(blockNumber);
    setCurrentBlockTimestamp(block.timestamp);
  };

  React.useEffect(() => {
    if (match?.isExact) {
      setSelectedTokenID(Number(match.params.myto));
      setModalOpen(true);
    }
    if (active) {
      return;
    }
    injectedConnector.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injectedConnector);
      } else {
        activate(networkConnector);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only attempt to authorize automatically once

  React.useEffect(() => {
    if (!library || !chainId) {
      return;
    }
    library.removeAllListeners("block");

    updateCurrentState();

    library.on("block", () => {
      console.info("Updating data for new block");
      updateCurrentState();
    });

    return () => {
      library.removeAllListeners("block");
    };
  }, [library, account, chainId]);

  React.useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalOpen]);

  const mintedStatuses = React.useMemo(() => {
    if (USE_LOCAL_DATA_ONLY) {
      return Array(MAX_NUMBER_OF_MYTOS).fill(false);
    }
    if (!energyBlockLevel) {
      return null;
    }
    return energyBlockLevel.map((blockLevel) => !blockLevel.isZero());
  }, [energyBlockLevel]);

  const areAllClaimed = React.useMemo(() => {
    return (
      !!mintedStatuses &&
      mintedStatuses
        .slice(0, MAX_CLAIMABLE_TOKEN_ID + 1)
        .every((value) => value)
    );
  }, [mintedStatuses]);

  const userHasClaimed = React.useMemo(() => {
    if (!ownedTokenIDs) {
      return false;
    }
    // Users can only claim 1 MytoByte during claiming
    return !areAllClaimed && ownedTokenIDs.length > 0;
  }, [ownedTokenIDs, areAllClaimed]);

  const updateSelectedTokenID = (tokenID: number) => {
    setSelectedTokenID(tokenID);
    history.push(`/myto/${tokenID}`);
  };

  const onOpenModal = React.useCallback(
    (tokenID) => {
      updateSelectedTokenID(tokenID);
      setModalOpen(true);
    },
    [updateSelectedTokenID]
  );

  const onCloseModal = React.useCallback(() => {
    history.push(`/`);
    setModalOpen(false);
  }, [history]);

  const getNextViewableToken = () => {
    if (selectedTokenID < MAX_CLAIMABLE_TOKEN_ID) {
      return selectedTokenID + 1;
    }
    return (
      selectedTokenID +
      (mintedStatuses?.slice(selectedTokenID + 1).indexOf(true) || 0) +
      1
    );
  };

  const getPreviousViewableToken = () => {
    if (selectedTokenID <= MAX_CLAIMABLE_TOKEN_ID) {
      return selectedTokenID - 1;
    }
    return (
      selectedTokenID -
      1 -
      (mintedStatuses?.slice(0, selectedTokenID).reverse().indexOf(true) || 0)
    );
  };

  const scrollToCollection = () =>
    collectionRef &&
    collectionRef.current &&
    collectionRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToClaimable = () =>
    claimableRef &&
    claimableRef.current &&
    claimableRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToMytoDex = () =>
    mytodexRef &&
    mytodexRef.current &&
    mytodexRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToProjectStats = () =>
    projectStatsRef &&
    projectStatsRef.current &&
    projectStatsRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToFAQ = () =>
    faqRef &&
    faqRef.current &&
    faqRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToRoadmap = () =>
    roadmapRef &&
    roadmapRef.current &&
    roadmapRef.current.scrollIntoView({ behavior: "smooth" });

  const selectNextViewableMyto = () => {
    gtag &&
      gtag("event", "view_detail", {
        event_label: `${getNextViewableToken()}`,
      });
    updateSelectedTokenID(getNextViewableToken());
  };

  const selectPreviousViewableMyto = () => {
    gtag &&
      gtag("event", "view_detail", {
        event_label: `${getPreviousViewableToken()}`,
      });
    updateSelectedTokenID(getPreviousViewableToken());
  };

  const largestMintedToken = Math.max(
    mintedStatuses?.lastIndexOf(true) || 0,
    MAX_CLAIMABLE_TOKEN_ID
  );

  return (
    <div className="Home__root">
      <Header
        ownsMyto={(ownedTokenIDs?.length || 0) > 0}
        scrollToCollection={scrollToCollection}
        scrollToClaimable={scrollToClaimable}
        scrollToMytoDex={scrollToMytoDex}
        scrollToProjectStats={scrollToProjectStats}
        scrollToFAQ={scrollToFAQ}
        scrollToRoadMap={scrollToRoadmap}
      />
      {mintedStatuses && (
        <PageSection hasBottomDivider>
          <Hero
            areAllClaimed={areAllClaimed}
            mintedStatuses={mintedStatuses}
            onClickClaim={scrollToClaimable}
            onClickMytoByte={onOpenModal}
          />
        </PageSection>
      )}
      {ownedTokenIDs &&
        !!ownedTokenIDs.length &&
        energyBlockLevel &&
        energyFoodLevel &&
        currentBlockNumber &&
        energyRequired && (
          <PageSection hasBottomDivider>
            <Collection
              ref={collectionRef}
              ownedTokenIDs={ownedTokenIDs}
              energyBlockLevel={energyBlockLevel}
              energyFoodLevel={energyFoodLevel}
              currentBlockNumber={currentBlockNumber}
              areAllClaimed={areAllClaimed}
              energyRequired={energyRequired}
              userHasClaimed={userHasClaimed}
              onClickMytoByte={onOpenModal}
            />
          </PageSection>
        )}
      {mintedStatuses && (!areAllClaimed || USE_LOCAL_DATA_ONLY) && (
        <PageSection hasBottomDivider>
          <ClaimableMytos
            ref={claimableRef}
            mintedStatuses={mintedStatuses}
            onClickMytoByte={onOpenModal}
            userHasClaimed={userHasClaimed}
            currentBlockTimestamp={currentBlockTimestamp}
          />
        </PageSection>
      )}
      {mintedStatuses &&
        energyBlockLevel &&
        energyFoodLevel &&
        currentBlockNumber &&
        energyRequired && (
          <PageSection hasBottomDivider>
            <MytoDex
              ref={mytodexRef}
              mintedStatuses={mintedStatuses}
              energyBlockLevel={energyBlockLevel}
              energyFoodLevel={energyFoodLevel}
              currentBlockNumber={currentBlockNumber}
              areAllClaimed={areAllClaimed}
              energyRequired={energyRequired}
              userHasClaimed={userHasClaimed}
              onClickMytoByte={onOpenModal}
            />
          </PageSection>
        )}
      {energyRequired && mintedStatuses && (
        <PageSection hasBottomDivider>
          <FAQ
            ref={faqRef}
            energyRequired={energyRequired}
            numMytos={mintedStatuses?.length}
          />
        </PageSection>
      )}
      {mintedStatuses && energyRequired && !USE_LOCAL_DATA_ONLY && (
        <PageSection hasBottomDivider>
          <ProjectStats
            ref={projectStatsRef}
            mintedStatuses={mintedStatuses}
            energyRequiredToMint={energyRequired}
            areAllClaimed={areAllClaimed}
          />
        </PageSection>
      )}
      <PageSection hasBottomDivider>
        <Roadmap ref={roadmapRef} />
      </PageSection>
      <PageSection hasBottomDivider>
        <Community />
      </PageSection>
      <PageSection removeBottomPadding>
        <Footer />
      </PageSection>
      <MytoByteDetailModal
        onCloseModal={onCloseModal}
        modalOpen={modalOpen}
        tokenID={selectedTokenID}
        currentBlockNumber={currentBlockNumber}
        currentBlockTimestamp={currentBlockTimestamp}
        energyBlockLevel={energyBlockLevel[selectedTokenID]}
        energyFoodLevel={energyFoodLevel[selectedTokenID]}
        energyRequired={energyRequired}
        areAllClaimed={areAllClaimed}
        userHasClaimed={userHasClaimed}
        goToNextToken={selectNextViewableMyto}
        goToPrevToken={selectPreviousViewableMyto}
        largestMintedToken={largestMintedToken}
      />
    </div>
  );
}

export default Home;
