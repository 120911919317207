import { BigNumber } from "@ethersproject/bignumber";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { MAX_CLAIMABLE_TOKEN_ID } from "./Contract/contract-constants";
import MytoByteGridCell from "./MytoByteGridCell";
import "./MytoDex.scss";

interface MytoDexProps {
  mintedStatuses: Array<boolean>;
  energyBlockLevel: Array<BigNumber>;
  energyFoodLevel: Array<BigNumber>;
  currentBlockNumber: number;
  areAllClaimed: boolean;
  energyRequired: BigNumber;
  userHasClaimed: boolean;
  onClickMytoByte: (tokenID: number) => void;
}

const MytoDex = React.forwardRef<HTMLDivElement, MytoDexProps>((props, ref) => {
  const { mintedStatuses } = props;
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [pageSize, setCurrentPageSize] = React.useState<number>(25);

  return (
    <div ref={ref}>
      <h1>All MytoBytes</h1>
      <div>
        <InputLabel id="demo-simple-select-label">Page Size</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={pageSize}
          label="Page Size"
          onChange={(target) => {
            setCurrentPageSize(Number(target.target.value));
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </div>
      <div className="MytoDex__grid">
        {mintedStatuses &&
          mintedStatuses
            .slice(pageSize * currentPage, pageSize * currentPage + pageSize)
            .map((minted, index) => {
              const tokenID = index + pageSize * currentPage;
              return (
                <MytoByteGridCell
                  tokenID={tokenID}
                  onClick={props.onClickMytoByte}
                  reveal={minted || tokenID <= MAX_CLAIMABLE_TOKEN_ID}
                />
              );
            })}
      </div>
      <div className="MytoDex__navigation">
        <IconButton
          className="MytoByteDetailModal__navigation-button"
          aria-label="Prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 0}
        >
          <KeyboardArrowLeft />
        </IconButton>
        {currentPage + 1}
        <IconButton
          className="MytoByteDetailModal__navigation-button"
          aria-label="Next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage >= mintedStatuses.length / pageSize - 1}
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    </div>
  );
});

export default MytoDex;
