import MuiTooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import React from "react";
import MytoByteData from "./Data/myto-data-2.json";
import "./MytoByteGridCell.scss";

interface MytoByteGridCellProps {
  tokenID: number;
  onClick: (tokenID: number) => void;
  reveal: boolean;
  forceLargeImage?: boolean;
}

const MytoByteGridCell = (props: MytoByteGridCellProps) => {
  // TODO: consider if requiring here is smart or not; seems to pull all images into chunk
  const image = require(`./Img/MytoGIFs/${props.tokenID}.gif`);
  const questionMarkBox = require(`./Img/Icons/box.svg`);
  return (
    <div
      className="MytoByteGridCell"
      onClick={
        props.reveal
          ? () => {
              gtag &&
                gtag("event", "view_detail", {
                  event_label: `${props.tokenID}`,
                });
              props.onClick(props.tokenID);
            }
          : undefined
      }
    >
      <div>
        <b>
          #{props.tokenID}
          {props.reveal ? ` ${MytoByteData[props.tokenID].name}` : null}
        </b>
      </div>
      {props.reveal ? (
        <img
          className={classNames("MytoByteGridCell-image", {
            "MytoByteGridCell-image-force-large": !!props.forceLargeImage,
          })}
          src={image.default}
          alt={MytoByteData[props.tokenID].name}
        />
      ) : (
        <MuiTooltip title={"This Myto hasn't been minted yet"}>
          <img
            height={"50px"}
            className="MytoByteGridCell-image-not-minted"
            src={questionMarkBox.default}
            alt="?"
          />
        </MuiTooltip>
      )}
    </div>
  );
};

export default MytoByteGridCell;
