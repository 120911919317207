import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";

export enum ChainID {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
}

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [ChainID.MAINNET, ChainID.ROPSTEN, ChainID.RINKEBY],
});

export const networkConnector = new NetworkConnector({
  urls: { 1: "https://eth.mytobytes.com" },
  defaultChainId: 1,
});
