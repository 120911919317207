import { Button } from "@mui/material";
import React from "react";
import { DISCORD_URL, HAS_LAUNCHED, OPENSEA_URL } from "./constants";
import { MAX_CLAIMABLE_TOKEN_ID } from "./Contract/contract-constants";
import "./Hero.scss";
import MytoByteGridCell from "./MytoByteGridCell";

type CountDown = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

interface HeroProps {
  areAllClaimed: boolean;
  mintedStatuses: Array<boolean>;
  onClickClaim: () => void;
  onClickMytoByte: (tokenID: number) => void;
}

const Hero = (props: HeroProps) => {
  const [randomID, setRandomID] = React.useState<number | null>(null);

  const calculateTimeLeft = () => {
    const difference = +new Date(1634583600 * 1000) - +new Date();

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return null;
  };

  const [timeLeft, setTimeLeft] = React.useState<CountDown | null>(
    calculateTimeLeft()
  );

  React.useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const randomizeTokenIDs = React.useCallback(() => {
    const possibleIDs: Array<number> = [];
    props.mintedStatuses.forEach((minted, tokenID) => {
      if (
        tokenID !== randomID &&
        (minted || tokenID <= MAX_CLAIMABLE_TOKEN_ID)
      ) {
        possibleIDs.push(tokenID);
      }
      setRandomID(possibleIDs[Math.floor(Math.random() * possibleIDs.length)]);
    });
  }, [props.mintedStatuses, randomID]);

  React.useEffect(() => {
    if (randomID !== null) {
      return;
    }
    randomizeTokenIDs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomizeTokenIDs]); // No dep on randomID to prevent infinite randomization loop

  return (
    <div>
      <div className="Hero">
        <div>
          <h1 style={{ marginTop: "15px" }}>MytoBytes</h1>
          <p style={{ marginBottom: "20px" }}>
            MytoBytes is collection of machine learning-generated, pixel art NFT
            creatures that live on the Ethereum blockchain.
          </p>
          <p style={{ marginBottom: "20px" }}>
            MytoBytes are dynamic NFTs that build up energy over time, or when
            fed, and can "split" to mint new MytoByte NFTs for their owners.
          </p>
          <p style={{ marginBottom: "20px" }}>
            Each MytoByte is totally unique and 100% fun! There are 100 genesis
            MytoBytes available to claim for free and 900 more that can be
            created by splitting.
          </p>
          {!props.areAllClaimed && !!timeLeft && (
            <div style={{ marginBottom: "15px" }}>
              <strong>
                {`${String(timeLeft.hours).padStart(2, "0")}:${String(
                  timeLeft.minutes
                ).padStart(2, "0")}:${String(timeLeft.seconds).padStart(
                  2,
                  "0"
                )}`}{" "}
                until open & unlimited minting begins!
              </strong>
            </div>
          )}
          <div className="Hero__button-box">
            {HAS_LAUNCHED &&
              (props.areAllClaimed ? (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => window.open(OPENSEA_URL, "_blank")}
                >
                  Buy on OpenSea
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  onClick={props.onClickClaim}
                >
                  Claim!
                </Button>
              ))}
            <Button
              variant={HAS_LAUNCHED ? "outlined" : "contained"}
              size="large"
              onClick={() => {
                window.open(DISCORD_URL, "_blank");
                gtag && gtag("event", "click_discord");
              }}
            >
              Join us on Discord
            </Button>
          </div>
        </div>
        <div style={{ margin: "auto", marginTop: "20px" }}>
          {/* TODO: set the style of this image container appropriately */}
          {randomID !== null && (
            <div style={{ width: "287px" }}>
              <MytoByteGridCell
                tokenID={randomID}
                reveal
                onClick={props.onClickMytoByte}
                forceLargeImage
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5px",
                }}
              >
                <Button onClick={randomizeTokenIDs}>Randomize</Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
