import React from "react";
import "./Collection.scss";
import { BigNumber } from "@ethersproject/bignumber";
import MytoByteGridCell from "./MytoByteGridCell";

interface CollectionProps {
  ownedTokenIDs: Array<number>;
  energyBlockLevel: Array<BigNumber>;
  energyFoodLevel: Array<BigNumber>;
  currentBlockNumber: number;
  areAllClaimed: boolean;
  energyRequired: BigNumber;
  userHasClaimed: boolean;
  onClickMytoByte: (tokenID: number) => void;
}

const Collection = React.forwardRef<HTMLDivElement, CollectionProps>(
  (props, ref) => {
    const { ownedTokenIDs } = props;

    if (!ownedTokenIDs.length) {
      return null;
    }

    return (
      <div ref={ref}>
        <h1>Your MytoByte collection:</h1>
        <div className="Collection__grid">
          {ownedTokenIDs &&
            ownedTokenIDs.map((tokenID) => {
              return (
                <MytoByteGridCell
                  tokenID={tokenID}
                  onClick={props.onClickMytoByte}
                  reveal
                />
              );
            })}
        </div>
      </div>
    );
  }
);

export default Collection;
