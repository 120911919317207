import { Link } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { DISCORD_URL } from "./constants";
import "./Roadmap.scss";

const Roadmap = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div ref={ref} className="Roadmap__root">
      <h1>The MytoBytes Roadmap</h1>
      <p>
        We're in MytoBytes for the long haul and are excited to continue
        investing in the MytoByte metaverse. We recognize that no roadmap is
        ever truly complete and continue to welcome ideas and feedback.
      </p>
      <div className="Roadmap__card-container">
        <Card sx={{ color: "white" }} className="Roadmap__card">
          <CardContent sx={{ padding: "25px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <h1
                style={{ fontSize: "128px" }}
                className="Roadmap__section-number"
              >
                1
              </h1>
              <div className="Roadmap__divider" />
              <div>
                <h3 style={{ marginBottom: "5px" }}>Pre-Mint Launch</h3>
                <ul style={{ marginTop: "5px" }}>
                  <li>Community building</li>
                  <li>Whitelist spot giveaways</li>
                  <li>MytoByte Giveaways</li>
                  <li>Allocating Discord OG status</li>
                </ul>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card sx={{ color: "white" }} className="Roadmap__card">
          <CardContent sx={{ padding: "25px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <h1
                style={{ fontSize: "128px" }}
                className="Roadmap__section-number"
              >
                2
              </h1>
              <div className="Roadmap__divider" />
              <div>
                <h3 style={{ marginBottom: "5px" }}>
                  Minting Period (First 100)
                </h3>
                <ul style={{ marginTop: "5px" }}>
                  <li>Begins on Oct. 17th @ 12PM PDT</li>
                  <li>Whitelist only minting for 24 hours</li>
                  <li>Open minting begins after whitelist if any not minted</li>
                  <li>MytoBytes #0 - #4 reserved for marketing & team</li>
                </ul>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card sx={{ color: "white" }} className="Roadmap__card">
          <CardContent sx={{ padding: "25px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <h1
                style={{ fontSize: "128px" }}
                className="Roadmap__section-number"
              >
                3
              </h1>
              <div className="Roadmap__divider" />
              <div>
                <h3 style={{ marginBottom: "5px" }}>
                  Splitting & Beyond Period
                </h3>
                <ul style={{ marginTop: "5px" }}>
                  <li>
                    Splitting begins once all 100 Genesis MytoBytes are claimed
                  </li>
                  <li>
                    MytoBytes #100 - #999 are mintable via splitting existing
                    MytoBytes
                  </li>
                  <li>
                    <strong>All 1000 Minted:</strong> MytoByte family tree NFT
                    release
                  </li>
                  <ul>
                    <li>
                      We'll create a mintable NFT of the family tree for each
                      genesis MytoByte. Mintable by owner of each genesis
                      MytoByte.
                    </li>
                  </ul>
                  <li>
                    <strong>All 1000 Minted:</strong> we want the project to
                    keep going. We're looking at cool new ways to continue to
                    use energy after all are minted, new forms for MytoBytes to
                    assume, and more. Community feedback will heavily influence
                    future work.
                  </li>
                </ul>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <p style={{ textAlign: "center" }}>
        <Link
          href={DISCORD_URL}
          onClick={() => gtag && gtag("event", "click_discord")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Join us on Discord
        </Link>{" "}
        to discuss this roadmap & the future of MytoBytes
      </p>
    </div>
  );
});

export default Roadmap;
