export const MAX_CLAIMABLE_TOKEN_ID = 99;
export const MAX_NUMBER_OF_MYTOS = 1000;
export const ENERGY_PER_BLOCK = 500000000000;
export const INITIAL_ENERGY_AMOUNT = 100 ** 2 * ENERGY_PER_BLOCK;
export const USE_LOCAL_DATA_ONLY = false;
export const SPLITTING_COOLDOWN_NUM_BLOCKS = 20;
export const ROPSTEN_MYTOBYTE_CONTRACT_ADDRESS = "";
export const RINKEBY_MYTOBYTE_CONTRACT_ADDRESS = "";
export const MAINNET_MYTOBYTE_CONTRACT_ADDRESS =
  "0xe4aed2e8519692411736ad7e496ad42b38511089";
export const WHITELIST_MINT_START_TIME = 1634497200;
export const OPEN_MINT_START_TIME = 1634583600;
export const WHITELISTED_ADDRESSES = new Set([
  "0xBF5126f3a72D01FaBC86599147621B350d166148",
  "0xa66d687d07D4550fE3985c42E66f7Ff47C73D4F2",
  "0x855d9e95864f55a9dd11842fdcb1bce8b3f0f4e3",
  "0x1b1fd868497a056BDb01aF0046DD1b3658025a07",
  "0x998ad9E7acb15F6ef9B235586694377ED0BBa071",
  "0x6b711E387693CDeD325c5A0C334594060034a1a8",
  "0x4718b7c30FaE5B9bAd79c23893bed90480dD7F29",
  "0x762A18607948e3F15900Ac9aaA9Adb2cD923EaCd",
  "0xAddc39cE24076366276f702864E0a4c0aB9798f8",
  "0x45246798bc93998834e9ACb0Ef99c3591D6a40e4",
  "0x5b15b440F1e013417D3251fb23d8d7e4c8ecCa1B",
  "0x7cf3a9c18a42b17d654D5B4754b04122C5852699",
  "0x75256A46E98Eb0F9c4eefc197eb3DD88d559a771",
  "0xa2E64ffa0Ac0915f5D712D7894BcF36d066914E0",
  "0x358774bd38a2dE8A086Ba2e4698EF698FAbD77A9",
  "0x6Bb14628D9012632270d0e72b68680c79C13A4e0",
  "0x9746e2b1Eb03e768528B8a3a48e2cC130148b0D7",
  "0xa26bEf53b2c0bDbFf0483eF26903B7E8cb56e293",
  "0x2f40303b2aD433600d361623B25c3B29ebc5BF63",
  "0x557F5A10d8e637167e6eeb3e9e4bAF64dA5BB8C5",
  "0xffB7Cf068175F51b57a9ABCF402E185d35247ce9",
  "0x1D444268d81a968CD70e54349c22A6796b75dc94",
  "0xA5109D7E4790143a91D673Ba545405Bf396806CF",
  "0xc6758ee93ae28F20c5DC1120066926a1Fb039A84",
  "0xfE6De8ce24B5f4748f4F449988d911f855277305",
  "0xdB1043f63974E21FdCF5c21bC4786650274ae656",
  "0xe7bc8ef68cb17cd3f57c067574197d50b8a2e156",
  "0x6fF12939e0E3661bc1f3231719Fd7A5d6bEA717D",
  "0xF88a427c5bf29Acf58497c0088cBf7ca9836b7b2",
  "0x8a14b7523cc6A17CC88043422CDC31CAFC698735",
  "0x375Cf42640e384D04314cB1B76a36d1D2924460a",
  "0xA7b030B429B16863887bB9116E830dAE67f41180",
  "0xAB0922C53E751aBfc39121a54e9352F56F8Bb5C1",
  "0x23de05D1Ba1021F71972D473BDAAA573c4B49127",
  "0xe51DB2167c42688789099516771D138F2E650880",
  "0x28ba80750a604041333101F6b5862FB34f3c4983",
  "0x6b84e54C1c365Ed622A32C1004908cB24F31920B",
  "0x6adEd35C29C0cC7c0F47E812Da93edB24663CCED",
  "0x04c7CD363FeD04AaF4588C85ffCE40B3d39A6B58",
  "0x6faE085fCD63c0af5BBc79b685994d01614dd2A3",
  "0x0E5E0e2A57d67EEf8e2EFD5d63028a1781f52a5c",
  "0x07eab9EC9cB153cCE09C0b9709724E9c7da6dE50",
  "0x5AbCDc6449C956fb6C2eA0d8aA4eD1a3435DE3C6",
  "0x77b45b2D075de274F819164bAEeBF5028c02F694",
  "0xb8dc9d4bbeb6705fdba1f89458a4bc2a2066a6c9",
  "0x53B93d31449cDDEDf3fC1947B4463b4E14fcB660",
  "0xA132B68B76C68588cBBeB8AB7FA095ECEbA3b4b7",
  "0x796EA953c766426a94326e9E2fBbe6E1dDD2e8C4",
  "0x45514a9e6175243B25531EE2040E54484f203EF1",
  "0x13EbcF55E588867C148724a01939EE92217C2457",
  "0x8a5E9D942fc25b5c857e8BD92EF77254CC9958C9",
  "0x2A17610BEF781a22DBDEC43f3C1F1D43f79ed276",
  "0xfDD2e9e6ADD04693d60a6Da6557aA22324115137",
  "0x3b246F7F273C1485c67a7aF6d33A99ad5b9CcEC3",
  "0x29e7a95be9c1d165f8980f2d68dcadcae7510cb9",
  "0xc83Df80aF3Cc2efCF8e913Ce1D0E79298e3a27bd",
  "0xd5aDD412017E341200F1bbb67ba04C8b37eDA033",
  "0x7724E51Cdf622E9BCa7A943628df5206f586Ea40",
  "0x641C2fEF13fb417dB01EF955a54904a6400F8b07",
  "0xFB78D54DfC0D85ac550cC0B45F87e3C542de2323",
  "0xd5282b70E65972Ba948E962A887d144E463998B6",
  "0xe5d185930f6f44FB741d6F53370516993dc04F21",
  "0x34a32Ad4Ba1EA1eB02CCd3ed5B9af9a8D8ea07A8",
  "0xF1DF03EE36720911eD62dBc333DC7C76438512AA",
  "0xf5994869BA3A3208d4D9428aa3Ae96F6B8611159",
  "0xA4d4dc31cB5D895e91DE465cb65C1acD4AB19Bb5",
  "0x21C9548B23b7fF3850d7354D17D48db9217370d1",
  "0x779569050F8e8E6B0884197eB8Dfd8dB58e98D31",
  "0x555de2ffa43b6a84464a35dd66715d17c41aab52",
  "0x06C3Ce61922128Abc04f31Ca639b6D89dae6337e",
  "0x1D5522626729349812A71d812D14AF8AF1F7AA26",
  "0xeAAeEf2d916F2545DD404Aa40517Ff3fA478F8B7",
  "0x7De76521E9d63a25d653143a8B49C2967f7CbdD3",
  "0x27096C2f1f4ea71cf56857D7752E24101B4A1eB8",
  "0xD969F09fe55FfB0e64954fc51E2D8af764caa8b0",
  "0x1984595924A5D9B7A31bfdCdb8A1319836EE5297",
  "0x05674bEeec564F26601Ca7b48ABd2D3c4C8Ae6A1",
  "0x803616FD9a4F6625d94000f01781993aAf2e5a89",
  "0x4F9a8E7ddDEE5F9737BafAD382fa3BB119FC80C4",
  "0xA5d117A73f92D55B1bb586c7488b11FD1833B029",
  "0x953878d1697Fa634C203d56925E4AF466EAa2E9E",
  "0x6B33568F47C43cC39fbFBd1EA2D24d23B0eAfb3c",
  "0xE65CD95bC3A66CcDC15584aB90Dfe2Fb5E0157fF",
  "0xf119b02deadf9193a0d0ae109a6740a97cef7bcf",
  "0x585142ebBA458B681caea61Bb178E529EdAd23f4",
  "0x33F72dB33eFf4B242A1272A21714C03D9Dd658F5",
  "0x1440a459D3037ab11A1bD9fb549e20aDf5481943",
  "0x3765240be64aF984bC5bB3B6A2988e431d668f6B",
  "0xe2fa5755897Dd2cc8Ad8442C846FB02D2d485841",
  "0x51EC15594230DDf21A7EA5A4aC392BB8Dbda527E",
  "0x0ed0Cd71F594D5f5F4F6a8ebc36426e80b5198f1",
  "0x579FC3375ef2128CdCFE2f3B73366E550b6EfeB2",
  "0x6433df109046a44c6fcc2d69c0ff180f111a75e5",
  "0xde5e414ee30Edb91b6E7b12773caCB4DffB7Cbd6",
  "0x6Aa2078b3c0f57509f9Be832D8C9356ff6F77338",
]);
export const MYTOBTYE_CONTRACT = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimNotOnWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32[]", name: "merkleProof", type: "bytes32[]" },
    ],
    name: "claimOnWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "claimed",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "creator",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenID", type: "uint256" }],
    name: "creatorClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "energyBlockLevel",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "energyFoodLevel",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "energyRequired",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint16", name: "tokenID", type: "uint16" }],
    name: "feed",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "genTokensAvailable",
    outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getEnergyBlockLevel",
    outputs: [
      { internalType: "uint256[1000]", name: "", type: "uint256[1000]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getEnergyFoodLevel",
    outputs: [
      { internalType: "uint256[1000]", name: "", type: "uint256[1000]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "root",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "royaltyInfo",
    outputs: [
      { internalType: "address", name: "receiver", type: "address" },
      { internalType: "uint256", name: "royaltyAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "splitTokensAvailable",
    outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
    name: "tokenByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "index", type: "uint256" },
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "newRoot", type: "bytes32" }],
    name: "updateRoot",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
