import { BigNumber } from "@ethersproject/bignumber";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import React from "react";
import Modal from "react-modal";
import { USE_LOCAL_DATA_ONLY } from "./Contract/contract-constants";
import MytoByteDetail from "./MytoByteDetail";
import "./MytoByteDetailModal.scss";

type MytoByteDetailModalProps = {
  modalOpen: boolean;
  tokenID: number;
  currentBlockNumber?: number;
  currentBlockTimestamp: number;
  energyBlockLevel: BigNumber;
  energyFoodLevel: BigNumber;
  energyRequired?: BigNumber;
  areAllClaimed: boolean;
  userHasClaimed: boolean;
  onCloseModal: () => void;
  goToNextToken: () => void;
  goToPrevToken: () => void;
  largestMintedToken: number;
};

const MytoByteDetailModal: React.FC<MytoByteDetailModalProps> = (props) => {
  if (
    !USE_LOCAL_DATA_ONLY &&
    (!props.currentBlockNumber || !props.energyRequired)
  ) {
    return null;
  }
  return (
    <Modal
      className="MytoByteDetailModal__root"
      isOpen={props.modalOpen}
      onRequestClose={props.onCloseModal}
    >
      <div className="MytoByteDetailModal__body">
        <div className="MytoByteDetailModal__navigation-button-container">
          <IconButton
            className="MytoByteDetailModal__navigation-button"
            aria-label="Prev"
            onClick={props.goToPrevToken}
            disabled={props.tokenID === 0}
          >
            {props.tokenID !== 0 && (
              <KeyboardArrowLeft sx={{ color: "white" }} />
            )}
          </IconButton>
        </div>
        {USE_LOCAL_DATA_ONLY ? (
          <MytoByteDetail
            tokenID={props.tokenID}
            goToNextToken={props.goToNextToken}
            goToPrevToken={props.goToPrevToken}
            useLocalData={USE_LOCAL_DATA_ONLY}
          />
        ) : (
          <MytoByteDetail
            tokenID={props.tokenID}
            currentBlockNumber={props.currentBlockNumber || 0}
            energyBlockLevel={props.energyBlockLevel}
            energyFeedLevel={props.energyFoodLevel}
            areAllClaimed={props.areAllClaimed}
            energyRequired={props.energyRequired || BigNumber.from(0)}
            userHasClaimed={props.userHasClaimed}
            currentBlockTimestamp={props.currentBlockTimestamp}
            goToNextToken={props.goToNextToken}
            goToPrevToken={props.goToPrevToken}
            useLocalData={USE_LOCAL_DATA_ONLY}
          />
        )}
        <div className="MytoByteDetailModal__navigation-button-container">
          <IconButton
            className="MytoByteDetailModal__navigation-button"
            aria-label="Next"
            onClick={props.goToNextToken}
            disabled={props.tokenID === props.largestMintedToken}
          >
            {props.tokenID !== props.largestMintedToken && (
              <KeyboardArrowRight sx={{ color: "white" }} />
            )}
          </IconButton>
        </div>
      </div>
      <div className="MytoByteDetailModal__closeButton">
        <IconButton onClick={props.onCloseModal}>
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      </div>
    </Modal>
  );
};

export default MytoByteDetailModal;
