import { BigNumber } from "@ethersproject/bignumber";
import { Contract } from "@ethersproject/contracts";
import { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { contractAddressForChainID } from "../utils";
import { ChainID } from "./Connectors";
import { MYTOBTYE_CONTRACT } from "./contract-constants";
import getMerkleTreeProof from "./MerkleTree";

const createContract = (library: Web3Provider, chainID: ChainID) => {
  if (library.connection.url === "eth.mytobytes.com") {
    const provider = new JsonRpcProvider("https://eth.mytobytes.com");
    return new Contract(
      contractAddressForChainID(1) as string,
      MYTOBTYE_CONTRACT,
      provider
    );
  }
  return new Contract(
    contractAddressForChainID(chainID) as string,
    MYTOBTYE_CONTRACT,
    library.getSigner()
  );
};

/* ------------ READ METHODS ------------ */

export const getBalanceForAccount = async (
  account: string,
  library: Web3Provider,
  chainID: number
) => {
  const contract = createContract(library, chainID);
  const balance = await contract.balanceOf(account);
  return balance.toNumber();
};

export const getTotalSupply = async (
  library: Web3Provider,
  chainID: number
) => {
  const contract = createContract(library, chainID);
  const totalSupply = await contract.totalSupply();
  return totalSupply.toNumber();
};

export const getOwnedTokenDataForAccount = async (
  account: string,
  library: Web3Provider,
  chainID: number
): Promise<Array<number>> => {
  const contract = createContract(library, chainID);
  const numTokensAccountOwns = await getBalanceForAccount(
    account,
    library,
    chainID
  );
  if (numTokensAccountOwns === 0) {
    return [];
  }
  const tokenPromises = [];
  for (let i = 0; i < numTokensAccountOwns; i++) {
    tokenPromises.push(contract.tokenOfOwnerByIndex(account, i));
  }

  // Get all TokenIDs that account owns
  const tokenIDsOwned = await Promise.all(tokenPromises);
  return tokenIDsOwned.map((tokenID) => {
    return tokenID.toNumber();
  });
};

export const getEnergyBlockLevel = async (
  library: Web3Provider,
  chainID: number
) => {
  const contract = createContract(library, chainID);
  const energyBlockLevel = await contract.getEnergyBlockLevel();
  return energyBlockLevel;
};

export const getEnergyFoodLevel = (
  library: Web3Provider,
  chainID: number
): Promise<Array<BigNumber>> => {
  const contract = createContract(library, chainID);
  return contract.getEnergyFoodLevel();
};

export const energyRequiredToMint = (
  library: Web3Provider,
  chainID: number
): Promise<BigNumber> => {
  const contract = createContract(library, chainID);
  return contract.energyRequired();
};

export const ownerOf = (
  tokenID: number,
  library: Web3Provider,
  chainID: number
): Promise<string> => {
  const contract = createContract(library, chainID);
  return contract.ownerOf(tokenID);
};

/* ------------ WRITE METHODS ------------ */

export const claimOnWhitelist = (
  library: Web3Provider,
  chainID: number,
  account: string
) => {
  const contract = createContract(library, chainID);
  const proof = getMerkleTreeProof(account);
  const overrides = {
    gasLimit: 300000,
  };
  return contract.claimOnWhitelist(proof, overrides);
};

export const claimNotOnWhitelist = (library: Web3Provider, chainID: number) => {
  const contract = createContract(library, chainID);
  const overrides = {
    gasLimit: 250000,
  };
  return contract.claimNotOnWhitelist(overrides);
};

export const feed = (
  tokenID: number,
  amount: BigNumber,
  library: Web3Provider,
  chainID: number
) => {
  const contract = createContract(library, chainID);
  const overrides = {
    value: amount,
    gasLimit: 300000,
  };
  contract.feed(tokenID, overrides);

  // TODO: use the response from the Contract method call to wait to see if any mints, then tell user: https://docs.ethers.io/v5/api/contract/contract/#contract-functionsSend
};
