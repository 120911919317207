import { bufferToHex, keccak256, keccakFromString } from "ethereumjs-util";
import { utils } from "ethers";
import { WHITELISTED_ADDRESSES } from "./contract-constants";

class MerkleTree {
  elements: any;
  layers: any;
  constructor(elements: any) {
    // Filter empty strings and hash elements
    this.elements = elements
      .filter((el: any) => el)
      .map((el: any) =>
        Buffer.from(utils.solidityKeccak256(["address"], [el]).substr(2), "hex")
      );

    // Sort elements
    this.elements.sort(Buffer.compare);
    // Deduplicate elements
    this.elements = this.bufDedup(this.elements);

    // Create layers
    this.layers = this.getLayers(this.elements);
  }

  getLayers(elements: any) {
    if (elements.length === 0) {
      return [[""]];
    }

    const layers = [];
    layers.push(elements);

    // Get next layer until we reach the root
    while (layers[layers.length - 1].length > 1) {
      layers.push(this.getNextLayer(layers[layers.length - 1]));
    }

    return layers;
  }

  getNextLayer(elements: any) {
    // @ts-ignore
    return elements.reduce((layer, el, idx, arr) => {
      if (idx % 2 === 0) {
        // Hash the current element with its pair element
        layer.push(this.combinedHash(el, arr[idx + 1]));
      }

      return layer;
    }, []);
  }

  // @ts-ignore
  combinedHash(first, second) {
    if (!first) {
      return second;
    }
    if (!second) {
      return first;
    }

    return keccak256(this.sortAndConcat(first, second));
  }

  getRoot() {
    return this.layers[this.layers.length - 1][0];
  }

  getHexRoot() {
    return bufferToHex(this.getRoot());
  }

  // @ts-ignore
  getProof(el) {
    let idx = this.bufIndexOf(el, this.elements);

    if (idx === -1) {
      throw new Error("Element does not exist in Merkle tree");
    }

    // @ts-ignore
    return this.layers.reduce((proof, layer) => {
      const pairElement = this.getPairElement(idx, layer);

      if (pairElement) {
        proof.push(pairElement);
      }

      idx = Math.floor(idx / 2);

      return proof;
    }, []);
  }

  // @ts-ignore
  getHexProof(el) {
    const proof = this.getProof(el);

    return this.bufArrToHexArr(proof);
  }

  // @ts-ignore
  getPairElement(idx, layer) {
    const pairIdx = idx % 2 === 0 ? idx + 1 : idx - 1;

    if (pairIdx < layer.length) {
      return layer[pairIdx];
    } else {
      return null;
    }
  }

  // @ts-ignore
  bufIndexOf(el, arr) {
    let hash;

    // Convert element to 32 byte hash if it is not one already
    if (el.length !== 32 || !Buffer.isBuffer(el)) {
      hash = keccakFromString(el);
    } else {
      hash = el;
    }

    for (let i = 0; i < arr.length; i++) {
      if (hash.equals(arr[i])) {
        return i;
      }
    }

    return -1;
  }

  // @ts-ignore
  bufDedup(elements) {
    // @ts-ignore
    return elements.filter((el, idx) => {
      return idx === 0 || !elements[idx - 1].equals(el);
    });
  }

  // @ts-ignore
  bufArrToHexArr(arr) {
    // @ts-ignore
    if (arr.some((el) => !Buffer.isBuffer(el))) {
      throw new Error("Array is not an array of buffers");
    }

    // @ts-ignore
    return arr.map((el) => "0x" + el.toString("hex"));
  }

  // @ts-ignore
  sortAndConcat(...args) {
    return Buffer.concat([...args].sort(Buffer.compare));
  }
}

const getMerkleTreeProof = (account: string) => {
  const elements = Array.from(WHITELISTED_ADDRESSES);
  const merkleTree = new MerkleTree(elements);
  const accountHex = Buffer.from(
    utils.solidityKeccak256(["address"], [account]).substr(2),
    "hex"
  );

  const proof = merkleTree.getHexProof(accountHex);
  return proof;
};

export const getMerkleTreeRoot = () => {
  const elements = Array.from(WHITELISTED_ADDRESSES);
  const hexElements = elements.map((element) => {
    return Buffer.from(element, "hex");
  });
  const merkleTree = new MerkleTree(elements);
  const root = merkleTree.getHexRoot();
  return root;
};

export default getMerkleTreeProof;
