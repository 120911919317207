import { BigNumber } from "@ethersproject/bignumber";
import { formatEther } from "@ethersproject/units";
import React from "react";
import {
  ENERGY_PER_BLOCK,
  MAX_CLAIMABLE_TOKEN_ID,
} from "./Contract/contract-constants";

interface ProjectStatsProps {
  mintedStatuses: Array<boolean>;
  energyRequiredToMint: BigNumber;
  areAllClaimed: boolean;
}

const ProjectStats = React.forwardRef<HTMLDivElement, ProjectStatsProps>(
  (props, ref) => {
    const numClaimed = React.useMemo(() => {
      return props.mintedStatuses
        .slice(0, MAX_CLAIMABLE_TOKEN_ID + 1)
        .filter((minted) => minted).length;
    }, [props.mintedStatuses]);

    const numMinted = React.useMemo(() => {
      return props.mintedStatuses
        .slice(MAX_CLAIMABLE_TOKEN_ID + 1, props.mintedStatuses.length)
        .filter((minted) => minted).length;
    }, [props.mintedStatuses]);

    const blocksRequiredForMint = props.energyRequiredToMint
      .div(ENERGY_PER_BLOCK)
      .toNumber();
    const timeRequiredToMintInDays =
      (blocksRequiredForMint * 15) / 60 / 60 / 24;
    return (
      <div ref={ref}>
        <h1>Stats</h1>
        <div>
          Number Genesis Minted: {numClaimed}/{MAX_CLAIMABLE_TOKEN_ID + 1}
        </div>
        <div>
          Number Split: {numMinted}/
          {props.mintedStatuses.length - (MAX_CLAIMABLE_TOKEN_ID + 1)}
        </div>
        {props.areAllClaimed ? (
          <>
            <div>
              Energy required to mint next MytoByte:{" "}
              {formatEther(props.energyRequiredToMint)} ETH
            </div>
            <div>
              {formatEther(props.energyRequiredToMint)} ETH's worth of energy is{" "}
              {blocksRequiredForMint} blocks or {timeRequiredToMintInDays} days.
              (Assuming 15 second blocks)
            </div>
          </>
        ) : (
          <p>
            Check back here for splitting stats once all first 100 genesis
            MytoBytes are minted!
          </p>
        )}
      </div>
    );
  }
);

export default ProjectStats;
