import { Web3Provider } from "@ethersproject/providers";
import { Button, Link } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import "./ClaimableMytos.scss";
import { TWITTER_URL } from "./constants";
import {
  MAX_CLAIMABLE_TOKEN_ID,
  OPEN_MINT_START_TIME,
  USE_LOCAL_DATA_ONLY,
  WHITELISTED_ADDRESSES,
  WHITELIST_MINT_START_TIME,
} from "./Contract/contract-constants";
import {
  claimNotOnWhitelist,
  claimOnWhitelist,
} from "./Contract/ContractInterface";
import MytoByteGridCell from "./MytoByteGridCell";

interface ClaimableMytosProps {
  mintedStatuses: Array<boolean>;
  onClickMytoByte: (tokenID: number) => void;
  userHasClaimed: boolean;
  currentBlockTimestamp: number;
}

const ClaimableMytos = React.forwardRef<HTMLDivElement, ClaimableMytosProps>(
  (props, ref) => {
    const { mintedStatuses } = props;
    const { library, chainId, account } = useWeb3React<Web3Provider>();

    const clickClaimOnWhitelist = React.useCallback(() => {
      if (!library || !chainId || !account) {
        return;
      }

      claimOnWhitelist(library, chainId, account);
      // TODO: add catch for error handling
    }, [library, chainId, account]);

    const clickClaimNotOnWhitelist = React.useCallback(() => {
      if (!library || !chainId || !account) {
        return;
      }

      claimNotOnWhitelist(library, chainId);
      // TODO: add catch for error handling
    }, [library, chainId, account]);

    const userCanClaim = React.useMemo(() => {
      if (!account || USE_LOCAL_DATA_ONLY) {
        return false;
      }

      // Minting has not begun for anyone
      if (props.currentBlockTimestamp < WHITELIST_MINT_START_TIME) {
        return false;
      }

      // User has already claimed their 1 MytoByte inside whitelist period
      // Open mint is unlimited, so don't return here once open mint starts
      if (
        props.currentBlockTimestamp < OPEN_MINT_START_TIME &&
        props.userHasClaimed
      ) {
        return false;
      }

      // In whitelisting claim period
      if (
        !(
          WHITELISTED_ADDRESSES.has(account.toUpperCase()) ||
          WHITELISTED_ADDRESSES.has(account.toLowerCase()) ||
          WHITELISTED_ADDRESSES.has(account)
        ) &&
        props.currentBlockTimestamp < OPEN_MINT_START_TIME
      ) {
        return false;
      }

      return true;
    }, [props.currentBlockTimestamp, account, props.userHasClaimed]);

    const isAfterWhitelistPeriod =
      props.currentBlockTimestamp >= OPEN_MINT_START_TIME;

    return (
      <div ref={ref}>
        {userCanClaim && (
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              size="large"
              onClick={
                isAfterWhitelistPeriod
                  ? clickClaimNotOnWhitelist
                  : clickClaimOnWhitelist
              }
            >
              Claim!
            </Button>
          </div>
        )}
        <h1 style={{ marginBottom: "10px" }}>Claimable MytoBytes</h1>
        <div className="Claimable_Myto__grid">
          {mintedStatuses &&
            mintedStatuses
              .slice(0, MAX_CLAIMABLE_TOKEN_ID + 1)
              .map((minted, tokenID) => {
                if (minted) {
                  return null;
                }
                return (
                  <div className={"Claimable_Myto__cell-container"}>
                    <MytoByteGridCell
                      tokenID={tokenID}
                      onClick={props.onClickMytoByte}
                      reveal={minted || tokenID <= MAX_CLAIMABLE_TOKEN_ID}
                    />
                  </div>
                );
              })}
        </div>
        {USE_LOCAL_DATA_ONLY && (
          <p style={{ textAlign: "center" }}>
            New MytoBytes are being revealed often! Check{" "}
            <Link
              href={TWITTER_URL}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => gtag && gtag("event", "click_twitter")}
            >
              our Twitter
            </Link>{" "}
            for reveals.
          </p>
        )}
      </div>
    );
  }
);

export default ClaimableMytos;
