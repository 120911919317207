import { ChainID } from "./Contract/Connectors";
import {
  MAINNET_MYTOBYTE_CONTRACT_ADDRESS,
  ROPSTEN_MYTOBYTE_CONTRACT_ADDRESS,
  RINKEBY_MYTOBYTE_CONTRACT_ADDRESS,
} from "./Contract/contract-constants";

// TODO: once MAINNET_MYTOBYTE_CONTRACT_ADDRESS is non-null, remove | null from return type
export const contractAddressForChainID = (chainID: number): string | null => {
  switch (chainID) {
    case ChainID.MAINNET:
      return MAINNET_MYTOBYTE_CONTRACT_ADDRESS;
    case ChainID.ROPSTEN:
      return ROPSTEN_MYTOBYTE_CONTRACT_ADDRESS;
    case ChainID.RINKEBY:
      return RINKEBY_MYTOBYTE_CONTRACT_ADDRESS;
  }
  return null;
};

const baseEtherScanURLForChainID = (chainID: number) => {
  let subdomain = "";
  switch (chainID) {
    case ChainID.MAINNET:
      subdomain = "";
      break;
    case ChainID.ROPSTEN:
      subdomain = "ropsten.";
      break;
    case ChainID.RINKEBY:
      subdomain = "rinkeby.";
  }
  return `https://${subdomain}etherscan.io`;
};

export const etherScanAddressLinkForChainID = (
  chainID: number,
  address: string
) => {
  return `${baseEtherScanURLForChainID(chainID)}/address/${address}`;
};

export const shortEtherAddress = (address: string) => {
  return address.substr(0, 6) + "..." + address.slice(-4);
};

export const etherScanTokenLinkForChainID = (
  chainID: number,
  tokenID: number | null
) => {
  const baseTokenURL = `${baseEtherScanURLForChainID(
    chainID
  )}/token/${contractAddressForChainID(chainID)}`;
  if (tokenID === null) {
    return baseTokenURL;
  }
  return baseTokenURL + `?a=${tokenID}`;
};

const baseOpenSeaURLForChainID = (chainID: number): string | null => {
  let subdomain = "";
  switch (chainID) {
    case ChainID.MAINNET:
      subdomain = "";
      break;
    case ChainID.RINKEBY:
      subdomain = "testnets.";
      break;
    default:
      return null;
  }
  return `https://${subdomain}opensea.io`;
};

export const openSeaTokenURLForChainID = (
  chainID: ChainID,
  tokenID: number
): string => {
  const baseURL = baseOpenSeaURLForChainID(chainID);
  if (!baseURL) {
    throw "Unexpected Chain Id";
  }
  return `${baseURL}/assets/${contractAddressForChainID(chainID)}/${tokenID}`;
};
