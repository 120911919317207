import { BigNumber } from "@ethersproject/bignumber";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther, parseEther } from "@ethersproject/units";
import { Button, Link } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import Countdown from "react-countdown";
import Tooltip from "./Components/Tooltip";
import {
  ENERGY_PER_BLOCK,
  MAX_CLAIMABLE_TOKEN_ID,
  SPLITTING_COOLDOWN_NUM_BLOCKS,
} from "./Contract/contract-constants";
import { feed, ownerOf } from "./Contract/ContractInterface";
import { AttributeTypes } from "./Data/data-constants";
import MytoByteData from "./Data/myto-data-2.json";
import "./MytoByteDetail.scss";
import {
  etherScanAddressLinkForChainID,
  etherScanTokenLinkForChainID,
  openSeaTokenURLForChainID,
  shortEtherAddress,
} from "./utils";

/*
Myto states:
- unrevealed: when tokenID > max claimable token ID and not minted
    - action: none
- claimable: when tokenID <= max claimable tokenID, not minted, and user has not claimed before
    - action: claim
- unclaimable: when tokenID <= max claimable tokenID, not minted, and user has claimed before (since users can only claim 1 token)
    - action: none
- minted unfeedable: when energyBlockLevel for this tokenID is not zero (minted), but not all have been claimed
    - action: none
- minted feedable: when energyBlockLevel for this tokenID is not zero (minted), and all have been claimed
    - action: feed
*/

enum MytoState {
  PRE_LAUNCH,
  UNREVEALED,
  CLAIMABLE,
  UNCLAIMABLE,
  MINTED_UNFEEDABLE,
  MINTED_FEEDABLE,
}

const isMinted = (mytoState: MytoState): boolean => {
  return (
    mytoState === MytoState.MINTED_UNFEEDABLE ||
    mytoState === MytoState.MINTED_FEEDABLE
  );
};

type MytoByteDetailViewProps =
  | {
      tokenID: number;
      currentBlockNumber: number;
      energyBlockLevel: BigNumber;
      energyFeedLevel: BigNumber;
      areAllClaimed: boolean;
      energyRequired: BigNumber;
      userHasClaimed: boolean;
      currentBlockTimestamp: number;
      goToNextToken: () => void;
      goToPrevToken: () => void;
      useLocalData: false;
    }
  | {
      tokenID: number;
      goToNextToken: () => void;
      goToPrevToken: () => void;
      useLocalData: true;
      energyBlockLevel?: undefined;
      areAllClaimed?: undefined;
      userHasClaimed?: undefined;
    };

const MytoByteDetail = (props: MytoByteDetailViewProps) => {
  const { account, library, chainId } = useWeb3React<Web3Provider>();
  const [ownerAddress, setOwnerAddress] = React.useState<string | null>(null);

  const mytoState = React.useMemo(() => {
    if (props.useLocalData) {
      return MytoState.PRE_LAUNCH;
    }
    const hasBeenMinted = !props.energyBlockLevel.isZero();
    if (hasBeenMinted) {
      return props.areAllClaimed
        ? MytoState.MINTED_FEEDABLE
        : MytoState.MINTED_UNFEEDABLE;
    }
    if (props.tokenID > MAX_CLAIMABLE_TOKEN_ID) {
      return MytoState.UNREVEALED;
    }
    return props.userHasClaimed || !account
      ? MytoState.UNCLAIMABLE
      : MytoState.CLAIMABLE;
  }, [
    props.tokenID,
    props.energyBlockLevel,
    props.areAllClaimed,
    props.userHasClaimed,
    account,
    props.useLocalData,
  ]);

  React.useEffect(() => {
    if (!library || !isMinted(mytoState) || !chainId) {
      return;
    }
    ownerOf(props.tokenID, library, chainId).then((owner) => {
      setOwnerAddress(owner);
    });
  }, [props.tokenID, mytoState, library, chainId]);

  if (!chainId) {
    return null;
  }

  //Adding one extra block since the soonest you can mint is the next block
  const currentEnergy = props.useLocalData
    ? BigNumber.from(0)
    : props.energyFeedLevel.add(
        BigNumber.from(props.currentBlockNumber)
          .add(1)
          .sub(props.energyBlockLevel)
          .mul(ENERGY_PER_BLOCK)
      );
  if (!library) {
    return null;
  }
  return (
    <div className="MytoByteDetailView__root">
      <div className="MytoByteDetailView__header">
        #{props.tokenID}: {MytoByteData[props.tokenID].name}
      </div>
      <MytoByteInfoSection
        goToNextToken={props.goToNextToken}
        goToPrevToken={props.goToPrevToken}
        tokenID={props.tokenID}
      />
      {mytoState === MytoState.CLAIMABLE && <ClaimableMytoActionSection />}
      {mytoState === MytoState.UNCLAIMABLE && <UnClaimableMytoActionSection />}
      {mytoState === MytoState.MINTED_UNFEEDABLE && ownerAddress && (
        <OwnedMytoButNotAllTokensMintedSection
          linkToTokenAddressOnEtherScan={etherScanTokenLinkForChainID(
            chainId,
            props.tokenID
          )}
          openSeaTokenURL={openSeaTokenURLForChainID(chainId, props.tokenID)}
          currentEnergy={currentEnergy}
          ownerAddress={ownerAddress}
          linkToOwnerAddressOnEtherScan={etherScanAddressLinkForChainID(
            chainId,
            ownerAddress
          )}
          name={MytoByteData[props.tokenID].name}
        />
      )}
      {mytoState === MytoState.MINTED_FEEDABLE &&
        !props.useLocalData &&
        ownerAddress && (
          <OwnedMytoFeedableSection
            currentEnergy={currentEnergy}
            energyRequired={props.energyRequired}
            currentBlockTimestamp={props.currentBlockTimestamp}
            feed={(amount) => {
              feed(props.tokenID, amount, library, chainId);
            }}
            ownerAddress={ownerAddress}
            linkToOwnerAddressOnEtherScan={etherScanAddressLinkForChainID(
              chainId,
              ownerAddress
            )}
            linkToTokenAddressOnEtherScan={etherScanTokenLinkForChainID(
              chainId,
              props.tokenID
            )}
            openSeaTokenURL={openSeaTokenURLForChainID(chainId, props.tokenID)}
            currentBlockNumber={props.currentBlockNumber}
            energyBlockLevel={props.energyBlockLevel}
            name={MytoByteData[props.tokenID].name}
            account={account}
          />
        )}
    </div>
  );
};

export default MytoByteDetail;

const ClaimableMytoActionSection: React.FC = () => {
  return (
    <div className="ClaimableMytoActionSection__roots">
      Hurray! This MytoByte is available to be claimed.
    </div>
  );
};

type UnClaimableMytoActionSectionProps = {};

const UnClaimableMytoActionSection: React.FC<UnClaimableMytoActionSectionProps> =
  (props) => {
    return <div></div>;
  };

type OwnedMytoButNotAllTokensMintedSectionProps = {
  linkToTokenAddressOnEtherScan: string;
  openSeaTokenURL: string;
  currentEnergy: BigNumber;
  ownerAddress: string;
  linkToOwnerAddressOnEtherScan: string;
  name: string;
};

const OwnedMytoButNotAllTokensMintedSection: React.FC<OwnedMytoButNotAllTokensMintedSectionProps> =
  (props) => {
    return (
      <div>
        <MytoByteEnergyLevelSection
          currentEnergy={props.currentEnergy}
          name={props.name}
        />
        You can't feed {props.name} right now since some of the genesis
        MytoBytes have not yet been claimed. Don't worry your myto is still
        building up energy that it can use once all the genesis MytoBytes are
        accounted for
        <OwnerInfoSection
          linkToOwnerAddressOnEtherScan={props.linkToOwnerAddressOnEtherScan}
          ownerAddress={props.ownerAddress}
        />
        <TokenExternalSitesSection
          linkToTokenAddressOnEtherScan={props.linkToTokenAddressOnEtherScan}
          openSeaTokenURL={props.openSeaTokenURL}
        />
      </div>
    );
  };

type OwnedMytoFeedableSectionProps = {
  currentEnergy: BigNumber;
  energyRequired: BigNumber;
  currentBlockTimestamp: number;
  feed: (amount: BigNumber) => void;
  ownerAddress: string;
  linkToOwnerAddressOnEtherScan: string;
  linkToTokenAddressOnEtherScan: string;
  openSeaTokenURL: string;
  currentBlockNumber: number;
  energyBlockLevel: BigNumber;
  name: string;
  account?: string | null;
};

const OwnedMytoFeedableSection: React.FC<OwnedMytoFeedableSectionProps> = (
  props
) => {
  const [feedAmount, setFeedAmount] = React.useState<string>("0");

  const energyShortOfMinting = props.energyRequired.sub(props.currentEnergy);
  const blocksRequiredForMint = energyShortOfMinting
    .div(ENERGY_PER_BLOCK)
    .toNumber();
  const secondsRequiredToMint =
    blocksRequiredForMint * 15 +
    (15 - (Math.round(Date.now() / 1000) - props.currentBlockTimestamp));
  const readyToSplit = energyShortOfMinting.lt(BigNumber.from(0));
  const onFeedingCooldown = BigNumber.from(props.currentBlockNumber)
    .sub(props.energyBlockLevel)
    .lte(SPLITTING_COOLDOWN_NUM_BLOCKS);
  const blocksUntilOffCooldown = props.energyBlockLevel
    .add(SPLITTING_COOLDOWN_NUM_BLOCKS)
    .sub(props.currentBlockNumber);

  return (
    <div>
      <MytoByteEnergyLevelSection
        currentEnergy={props.currentEnergy}
        name={props.name}
      />
      <div className="MytoByteDetailView__feedingSection">
        <div>
          <b>Energy required to mint:</b> {formatEther(props.energyRequired)}{" "}
          ETH
        </div>
        {readyToSplit ? (
          <>
            {!onFeedingCooldown && (
              <p>
                {props.name} is ready to split! Feed it 0 or more to mint
                another MytoByte.
              </p>
            )}
          </>
        ) : (
          <>
            <div>
              <b>
                {" "}
                Time till ready to split:
                <Tooltip message="This is based on an assumed 15 second block time" />
              </b>
              <Countdown date={Date.now() + secondsRequiredToMint * 1000} />
            </div>
            <div>
              <b>Energy required for {props.name} to split now: </b>
              {formatEther(energyShortOfMinting)} ETH
              {!!props.account && (
                <Button
                  onClick={() =>
                    setFeedAmount(formatEther(energyShortOfMinting))
                  }
                >
                  {" "}
                  Set{" "}
                </Button>
              )}
            </div>
          </>
        )}
        {onFeedingCooldown && (
          <p>
            {props.name} has split recently and can't be fed for another{" "}
            {blocksUntilOffCooldown.toNumber()} blocks!
          </p>
        )}
        {!!props.account && (
          <>
            <input
              onChange={(e) => {
                setFeedAmount(e.target.value);
              }}
              placeholder="Amount to feed"
              value={feedAmount}
              style={{ marginRight: "10px" }}
            />
            <Button
              onClick={() => props.feed(parseEther(feedAmount))}
              disabled={onFeedingCooldown}
              variant="contained"
            >
              Feed
            </Button>
          </>
        )}
      </div>
      <OwnerInfoSection
        linkToOwnerAddressOnEtherScan={props.linkToOwnerAddressOnEtherScan}
        ownerAddress={props.ownerAddress}
      />
      <TokenExternalSitesSection
        linkToTokenAddressOnEtherScan={props.linkToTokenAddressOnEtherScan}
        openSeaTokenURL={props.openSeaTokenURL}
      />
    </div>
  );
};

type LinkToTokenOnExternalSitesSectionProps = {
  linkToTokenAddressOnEtherScan: string;
  openSeaTokenURL: string;
};

const TokenExternalSitesSection: React.FC<LinkToTokenOnExternalSitesSectionProps> =
  (props) => {
    const openseaLogo = require(`./Img/ThirdPartyLogos/opensea.svg`);
    const etherscanLogo = require(`./Img/ThirdPartyLogos/etherscan-logo-circle.svg`);
    return (
      <div className="MytoByteDetailView__iconSection">
        <span className="MytoByteDetailView__icon-pretext">View on:</span>
        <Link
          href={props.linkToTokenAddressOnEtherScan}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="MytoByteDetailView__icon"
            alt="Etherscan Logo"
            src={etherscanLogo.default}
            height={25}
          />
        </Link>
        <Link
          href={props.openSeaTokenURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="MytoByteDetailView__icon"
            src={openseaLogo.default}
            height={25}
            alt="Opensea Logo"
          />
        </Link>
      </div>
    );
  };

type MytoByteEnergyLevelSectionProps = {
  currentEnergy: BigNumber;
  name: string;
};

const MytoByteEnergyLevelSection: React.FC<MytoByteEnergyLevelSectionProps> = (
  props
) => {
  return (
    <div className="MytoByteDetailView__currentEnergyLevel">
      <b>{props.name}’s current energy level: </b>
      {formatEther(props.currentEnergy)} ETH
    </div>
  );
};

type MytoByteInfoSectionProps = {
  tokenID: number;
  goToPrevToken: () => void;
  goToNextToken: () => void;
};

const MytoByteInfoSection: React.FC<MytoByteInfoSectionProps> = (props) => {
  const image = require(`./Img/MytoGIFs/${props.tokenID}.gif`);
  const attributes = MytoByteData[props.tokenID].attributes;
  const color = attributes.filter((attribute) => {
    return attribute.trait_type === AttributeTypes.COLOR;
  })[0];
  const generation = attributes.filter((attribute) => {
    return attribute.trait_type === AttributeTypes.GENERATION;
  })[0];
  const movement = attributes.filter((attribute) => {
    return attribute.trait_type === AttributeTypes.MOVEMENT_TYPE;
  })[0];
  const speed = attributes.filter((attribute) => {
    return attribute.trait_type === AttributeTypes.MOVEMENT_SPEED;
  })[0];
  const effect = attributes.filter((attribute) => {
    return attribute.trait_type === AttributeTypes.COLOR_EFFECT;
  })[0];
  const size = attributes.filter((attribute) => {
    return attribute.trait_type === AttributeTypes.SIZE;
  })[0];
  return (
    <div className="MytoByteInfoSection__root">
      <img
        className="MytoByteInfoSection__Image"
        src={image.default}
        alt={MytoByteData[props.tokenID].name}
      />
      <div>
        <h3>Properties</h3>
        <div>
          {AttributeTypes.COLOR}: {color.value}
        </div>
        <div>
          {AttributeTypes.SIZE}: {size.value}
        </div>
        <div>
          {AttributeTypes.GENERATION}: {generation.value}
        </div>
        <div>
          {AttributeTypes.MOVEMENT_TYPE}: {movement.value}
        </div>
        <div>
          {AttributeTypes.MOVEMENT_SPEED}: {speed.value}
        </div>
        <div>
          {AttributeTypes.COLOR_EFFECT}: {effect.value}
        </div>
      </div>
    </div>
  );
};

type OwnerInfoSectionProps = {
  ownerAddress: string;
  linkToOwnerAddressOnEtherScan: string;
};

const OwnerInfoSection: React.FC<OwnerInfoSectionProps> = (props) => {
  return (
    <div>
      Owner:{" "}
      <Link
        href={props.linkToOwnerAddressOnEtherScan}
        target="_blank"
        rel="noopener noreferrer"
      >
        {shortEtherAddress(props.ownerAddress)}
      </Link>
    </div>
  );
};
