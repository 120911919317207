import { BigNumber } from "@ethersproject/bignumber";
import { formatUnits, parseEther } from "@ethersproject/units";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { DISCORD_URL, OPENSEA_URL, TWITTER_URL } from "./constants";
import { ENERGY_PER_BLOCK } from "./Contract/contract-constants";
import { AttributeTypes } from "./Data/data-constants";

// const currencyFormatter = new Intl.NumberFormat("en-US", {
//   style: "currency",
//   currency: "USD",
//   maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
// });

const FAQRow = (props: {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
}) => {
  return (
    <Accordion
      defaultExpanded={!!props.defaultExpanded}
      sx={{ bgcolor: "#212428", color: "white" }}
      onChange={() =>
        gtag && gtag("event", "faq_interact", { event_label: props.title })
      }
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}>
        <h3>{props.title}</h3>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

interface FAQProps {
  energyRequired: BigNumber;
  numMytos: number;
}

const FAQ = React.forwardRef<HTMLDivElement, FAQProps>((props, ref) => {
  const [ethUSDPrice, setETHUSDPrice] = React.useState<number | null>(null);

  React.useEffect(() => {
    const fetchETHPrice = async () => {
      const response = await fetch(
        "https://api.coinbase.com/v2/prices/ETH-USD/sell"
      );
      const json = await response.json();
      setETHUSDPrice(parseInt(json.data.amount));
    };
    fetchETHPrice();
  }, [setETHUSDPrice]);

  const earnedUSDPerMonth = React.useMemo(() => {
    if (!ethUSDPrice) {
      return null;
    }
    return BigNumber.from((60 * 60 * 24 * 30) / 15)
      .mul(BigNumber.from(ENERGY_PER_BLOCK))
      .div(parseEther("1.0").div(BigNumber.from(ethUSDPrice)))
      .toString();
  }, [ethUSDPrice]);

  return (
    <div ref={ref}>
      <h1 style={{ marginBottom: "10px" }}>Frequently Asked Questions</h1>
      <FAQRow title="How do I get a MytoByte?" defaultExpanded>
        <p>
          The first 100 MytoBytes will be claimable for free (excluding Ethereum
          transaction costs).
        </p>
        <p>
          At launch, priority will be given to those on our whitelist. We're
          doing a whitelist to keep gas costs low at the launch date. Follow our
          <Link
            href={TWITTER_URL}
            onClick={() => gtag && gtag("event", "click_twitter")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </Link>{" "}
          and join our{" "}
          <Link
            href={DISCORD_URL}
            onClick={() => gtag && gtag("event", "click_discord")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </Link>{" "}
          for upcoming details on how to get onto the whitelist.
        </p>
        <p>
          After the first 100 MytoBytes have been claimed, you can purchase
          MytoBytes in marketplaces like{" "}
          <Link target="_blank" rel="noopener noreferrer" href={OPENSEA_URL}>
            Opensea
          </Link>
          .
        </p>
        <p>
          If you already own a MytoByte, then you can "split" to create new
          MytoBytes for yourself!
        </p>
      </FAQRow>
      <FAQRow title="How will the whitelist work? How can I get onto the whitelist?">
        <p>
          MytoBytes will begin minting on Oct. 17th @ 12PM PDT. For the first 24
          hours after the start time, only Ethereum wallet addresses that are
          whitelisted can mint MytoBytes.
        </p>
        <p>
          Each person on the whitelist will be guaranteed to be able to mint 1
          MytoByte.
        </p>
        <p>
          After these 24 hour whitelist period, any unminted MytoBytes in first
          100 will be available to claim freely with no limitations.
        </p>
        <p>
          We're doing a whitelist so that Ethereum gas fees don't rapidly rise
          in a first come first serve minting format and stay cheap for all.
        </p>
        <p>
          <Link
            href={DISCORD_URL}
            onClick={() => gtag && gtag("event", "click_discord")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Join us on Discord
          </Link>{" "}
          for details on how to get onto the whitelist.
        </p>
      </FAQRow>
      <FAQRow title="How much do MytoBytes cost to mint?">
        <p>
          Each MytoByte in the first 100 are free to mint (excluding Ethereum
          transaction fees).
        </p>
        <p>
          After the first 100, the final 900 MytoBytes can be minted by
          increasing your MytoBytes' energy level and "splitting" them.
        </p>
        <p>
          The cost to "split" is variable. See the section below for details.
        </p>
      </FAQRow>
      <FAQRow title='How do MytoBytes "split" to make new NFTs?'>
        <p>MytoBytes "split" to create their owners new MytoByte NFTs!</p>
        <p>
          Each MytoByte has an "energy" level, which is increased over time. You
          can also increase the "energy" level by "Feeding" your MytoByte
          Ethereum.
        </p>
        <p>
          There is a minimum required amount of energy to split that's shared
          across all MytoBytes.
        </p>
        <p>
          When your MytoByte's energy level goes past the energy level required
          to mint, you can feed your MytoByte which will cause it to split!
        </p>
        <p>
          Upon splitting, a MytoByte's energy level is reduced by the minimum
          amount of energy that was needed for it to split.
        </p>
      </FAQRow>
      <FAQRow title="How fast do MytoBytes increase their energy level?">
        <p>
          MytoBytes increase their energy level by{" "}
          {formatUnits(ENERGY_PER_BLOCK, "gwei").slice(0, -2)} GWEI worth of
          energy every Ethereum Block.
        </p>
        <p>
          This means every MytoByte earns the equivalent of approximately{" "}
          <strong>${earnedUSDPerMonth}</strong> USD worth of energy per month at
          the current ETH to USD price of ${ethUSDPrice}
        </p>
        <p>
          Note: energy bound to a MytoByte and is not transferable, tradable,
          changeable, or exchangeable for any other currencies.
        </p>
      </FAQRow>
      <FAQRow title="How does the minimum energy level to split change?">
        <p>
          Each time a MytoByte is split the minimum energy level to split others
          increases.
        </p>
        <p>
          The new minimum energy required becomes (number of Mytos minted)
          <sup>2</sup> · 5,000 GWEI after each split.
        </p>
      </FAQRow>
      <FAQRow title="Do my MytoBytes automatically split when they reach the required energy level to split?">
        <p>
          No. To split your MytoByte and create a new NFT, you'll need to feed
          your MytoByte - even if you don't feed it any ETH. Feeding is what
          triggers the split and mints the owner of that MytoByte a new token.
        </p>
      </FAQRow>
      <FAQRow title="Which MytoByte will I get when mine splits?">
        <p>The MytoByte that will split and be minted for you is random.</p>
        <p>
          Upon feeding, if your MytoByte has enough energy to split, then the
          newly minted MytoByte will be randomly picked between all unminted
          MytoBytes.
        </p>
        <p>
          All MytoBytes are completely unique and 1 of 1, so the MytoByte you
          mint will be completely new and freshly revealed!
        </p>
      </FAQRow>
      <FAQRow title="Are MytoBytes fully decentralized?">
        <p>
          Yes, MytoBytes are NFT's (Non-Fungible Tokens) on the Ethereum
          blockchain in a smart contract. Each token's assets (images &
          attributes) are hosted on{" "}
          <Link
            href="https://ipfs.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            IPFS
          </Link>
          , which is fully decentralized.
        </p>
        <p>
          While this web app is centralized, it's only an interface to the smart
          contract and all functionality is available without it.
        </p>
      </FAQRow>
      <FAQRow title="How many MytoBytes are there?">
        <p>{props.numMytos}</p>
      </FAQRow>
      <FAQRow title="What blockchain will this be on?">
        <p>Ethereum.</p>
      </FAQRow>
      <FAQRow title="Are any MytoBytes withheld from sale?">
        <p>
          Yes. 5 of the 100 claimable MytoBytes will be withheld for the team
          and marketing. The team members personally chose 1 MytoByte each from
          the collection.
        </p>
      </FAQRow>
      <FAQRow title="What attributes do MytoBytes have?">
        <p>
          Each MytoByte has 6 attribute types, with 30 total unique attributes:
        </p>
        <ul>
          <li>
            <strong>{AttributeTypes.COLOR}</strong>: primary color color of the
            MytoByte
          </li>
          <li>
            <strong>{AttributeTypes.GENERATION}</strong>: If the MytoByte was in
            the first 100 claimable, or in the other 900 splittable
          </li>
          <li>
            <strong>{AttributeTypes.MOVEMENT_TYPE}</strong>: movement pattern of
            the MytoByte
          </li>
          <li>
            <strong>{AttributeTypes.MOVEMENT_SPEED}</strong>: speed of the
            movement of the MytoByte
          </li>
          <li>
            <strong>{AttributeTypes.COLOR_EFFECT}</strong>: dynamic coloration
            of the MytoByte, if any
          </li>
          <li>
            <strong>{AttributeTypes.SIZE}</strong>: how large the MytoByte is
          </li>
        </ul>
      </FAQRow>
      <FAQRow title="How were MytoBytes created?">
        <p>MytoBytes are generated art made using machine learning.</p>
        <p>
          Specifically, MytoBytes were generated by a GAN (Generative
          Adversarial Network) trained on pixel art creatures from various
          sources. After generation, we hand-picked the best resuls and wrote a
          special script to animate each MytoByte's movement & color effect.
        </p>
      </FAQRow>
      <p style={{ textAlign: "center" }}>
        Still have a question? Ask us on{" "}
        <Link
          href={TWITTER_URL}
          onClick={() => gtag && gtag("event", "click_twitter")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </Link>
      </p>
    </div>
  );
});

export default FAQ;
